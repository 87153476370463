.GameCard {
    width: 250px;
    height: 200px;
    text-align: center;
    overflow: hidden;
}
.GameGrid {
    text-align: center;
    height: 100%;
    width: 100%;
}
.logobar {
    height: 120px;
    background-color: rgb(224, 221, 221);
    font-size: 20px;
    font-weight: 600;
}
.logo {
    width: 70px;
    height: 70px;
}

.left {
    text-align: left;
}

.actionbar{
    height: 80px;
    background-color: rgb(35, 35, 156);
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.hidden{
    display: none;
}
.transition{
    transition: visibility 0s, opacity 0.5s linear;
}
