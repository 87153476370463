.GameTicker {
}

.GameStacker{
    margin: 10px;
    width: 515px;
    overflow: hidden;
}
.hidden{
}
.show {
}